import React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
//import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useIntl, Link } from "gatsby-plugin-react-intl";
import ImagingTable from "../../components/products/ImagingTable";
import Slider from "react-slick";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

const ProductsNonMedicalDevice = ({ data, location }) => {
  const intl = useIntl();

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    //autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    slidesToShow: 1,
    //centerPadding: '100px',
    focusOnSelect: true,
    //centerMode: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 896,
        settings: {
          variableWidth: false,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <Layout location={location}>
      <Seo
        title={
          intl.locale === "en"
            ? `Acoustic X - the world first photoacoustic imaging solution`
            : `Acoustic X - die weltweit erste photoakustische Bildgebung`
        }
        description={
          intl.locale === "en"
            ? `Simultaneous imaging of light absorption and ultrasound which enables visualizing capillary vessels, distribution of blood, and differences of oxygen saturation.`
            : `Kombinierte Bildgebung von Ultraschall und Lichtabsorption ermöglicht die Darstellung von Kapillaren, Blutverteilung und Unterschiede der Sauerstoffsättigung.`
        }
        ogImage="https://images.ctfassets.net/t2ifj85iosf3/3UITFpsdm0QXobv3nyKq94/76ea125ceb3ada0a64459b64bee61da9/E176.png?w=1200&h=630&fit=fill"
      />
      <section className="sect bg-hellblau position-relative">
        <StaticImage
          src="../../images/products/imaging/header-acoustic-x@2x.png"
          className="sect_Product__catch___img2 bg-absolute d-sm-none bg-sm-relative"
          alt=""
        />
        <StaticImage
          src="../../images/products/imaging/header-acoustic-x-mobile@2x.png"
          className="sect_Product__catch___img2 bg-absolute d-sm-block bg-sm-relative"
          alt=""
        />

        <div className="container flex-row my-8">
          <div className="flex-column-6" />
          <div className="flex-column-6">
            <h1 className="h1">
              {intl.locale === "en"
                ? `Advanced Imaging for Detailed Diagnosis`
                : `Fortschrittliche Bildgebung für detailierte Diagnosen`}
            </h1>
            <p className="message-label-bold txt txt-rot">
              {intl.locale === "en" ? `ACOUSTIC X` : `ACOUSTIC X`}
            </p>
            <p>
              {intl.locale === "en"
                ? `The Acoustic X is a photoacoustic imaging machine that allows
  real-time image construction of absorber in vivo by transmitting
  LED pulse and detecting the ultrasonic emission resulted in
  photoacoustic effect. The selection of different LED wavelengths
  and the integration of ultrasound absorption visualize the
  location of the target substance.`
                : `Acoustic X ist ein photoakustisches Bildgebungsgerät. In Echtzeit werden Bilder des in vivo Absorber durch Senden von LED Impulsen und erkennen der Ultraschallabgabe, dem sogenannten photoakustischen Effekt, konstruiert. Durch die Auswahl verschiedener LED Wellenlänge und die Einbindung der Ultraschallaufnahme wird der Ort der Zielsubstanz visualisiert.`}
            </p>
          </div>
        </div>
      </section>

      <section className="sect p-0 bg-white">
        <div className="container flex-row mt-9">
          <div className="flex-column-7 pr-4">
            <h2 className="fs-11">
              {intl.locale === "en"
                ? `The World’s first plug and play multispectral handheld LED-based
  photoacoustic and ultrasound imaging system for clinical research.`
                : `Das weltweit erste Plug-and-Play-Multispektral-Handgerät auf LED-Basis mit photoakustischer und Ultraschall-Bildgebung für die klinische Forschung.`}
            </h2>
            <p className="mb-6">
              {intl.locale === "en"
                ? `Acoustic X is capable of simultaneously obtaining information
  through light absorption and ultrasound. Obtained information can
  be displayed on the monitor of the device. By combining these two
  methodology, Acoustic X can display information which was thought
  to be difficult to display, such as capillary vessels and
  differences of oxygen saturation.`
                : `Acoustic X ist in der Lage, gleichzeitig Informationen durch Lichtabsorption und Ultraschall zu erhalten. Die gewonnenen Informationen können auf dem Monitor des Geräts angezeigt werden. Durch die Kombination dieser beiden Methoden kann Acoustic X Informationen anzeigen, die bisher als schwer darstellbar galten, wie etwa Kapillargefäße und Unterschiede in der Sauerstoffsättigung.`}
            </p>
            <h3 className="txt txt-rot">
              {intl.locale === "en"
                ? `Support cutting-edge research`
                : `Unterstützung der Spitzenforschung`}
            </h3>
            <p>
              {intl.locale === "en"
                ? `Acoustic X aims to overcome difficulties of conventional
  photoacoustic devices, such as the expensive costs and requirement
  of vast amounts of electricity supply by adopting LED array light
  source`
                : `Acoustic X zielt darauf ab, die Schwierigkeiten herkömmlicher photoakustischer Geräte zu überwinden, etwa die hohen Kosten und den hohen Strombedarf, indem es eine LED-Lichtquelle einsetzt.`}
            </p>
          </div>
          <div className="flex-column-5">
            <p className="fs-6 fsi pb-3 bb-1">
              {intl.locale === "en"
                ? `Localize absorber`
                : `Lokalisiert den Absorber`}
            </p>
            <p className="fs-6 fsi pb-3 bb-1">
              {intl.locale === "en"
                ? `Detect distribution of blood (Hemoglobin)`
                : `Ermittelt die Blutverteilung (Hämoglobin)`}
            </p>
            <p className="fs-6 fsi pb-3 bb-1">
              {intl.locale === "en"
                ? `Functional imaging (such as oxygen saturation)`
                : `Funktionelle Bildgebung (z.B. Sauerstoffsättigung)`}
            </p>
            <p className="fs-6 fsi pb-3 bb-1">
              {intl.locale === "en"
                ? `Visualize artifacts such needle and marker clearly`
                : `Visualisiert Werkzeuge wie Nadeln und Biomarker deutlich`}
            </p>
          </div>
        </div>
      </section>

      <section className="sect sect_TreatmentCvd_2f8as2 bg-bls">
        <div className="container">
          <p className="message-label-bold txt txt-rot">
            {intl.locale === "en"
              ? `Videos and Webinars`
              : `Videos und Webinare`}
          </p>
          <p className="h3 font-weight-bold">
            {intl.locale === "en"
              ? `Watch the videos and webinars to learn how Acoustic X provides
  unique and vast clinical and research opportunities`
              : `Schauen Sie sich die Videos und Webinare an um zu lernen welche einzigartigen und umfangreichen klinischen und Forschungsmöglichkeiten Acoustic X bietet.`}
          </p>
          <div className="slide">
            <Slider {...settings}>
              <div className="slide__link">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/cKeHNXDj_7M"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <div className="w560">
                  <p className="message-label-bold">
                    {intl.locale === "en"
                      ? `Acoustic X Webinar #1: LED-based Photoacoustic Molecular
  Imaging`
                      : `Acoustic X Webinar #1: LED-basierte photoakustische Molekularbildgebung`}
                  </p>
                </div>
              </div>
              <div className="slide__link">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/xO1vsorJb3A"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <div className="w560">
                  <p className="message-label-bold">
                    {intl.locale === "en"
                      ? `Acoustic X Webinar #2: Tomographic imaging with an LED-based
  photoacoustic and ultrasound system`
                      : `Acoustic X Webinar #2: Tomographische Bildgebung mit LED-basierten photoakustischen und Ultraschall Systemen`}
                  </p>
                </div>
              </div>
              <div className="slide__link">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/r3ScA3JgkMk"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <div className="w560">
                  <p className="message-label-bold">
                    {intl.locale === "en"
                      ? `Acoustic X Webinar #3: LED-based photoacoustic imaging for
  detection and staging of inflammatory arthritis`
                      : `Acoustic X Webinar #3: LED-basierte photoakustische Bildgebung zur Erkennung und Einteilung von entzündlicher Arthritis`}
                  </p>
                </div>
              </div>
              <div className="slide__link">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/BfW_f5v8NrQ"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <div className="w560">
                  <p className="message-label-bold">
                    {intl.locale === "en"
                      ? `Acoustic X Webinar #4: Studies of precision and accuracy in
  LED-based photoacoustics`
                      : `Acoustic X Webinar #4: Studien zur Präzision und Richtigkeit der LED-basierten Photoaustik`}
                  </p>
                </div>
              </div>
              <div className="slide__link">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/7kAYHRpTg3Y"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <div className="w560">
                  <p className="message-label-bold">
                    {intl.locale === "en"
                      ? `Acoustic X Webinar #5: Guidance of minimally invasive
  peripheral vascular access procedures using LED-based
  photoacoustic imaging`
                      : `Acoustic X Webinar #5: Richtlinien zu minimal invasiven peripheren Gefäßzugangsverfahren mittels LED-basierter photoakustischer Bildgebung`}
                  </p>
                </div>
              </div>
              <div className="slide__link">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/NcIU2LLBNR8"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <div className="w560">
                  <p className="message-label-bold">
                    {intl.locale === "en"
                      ? `Acoustic X Webinar #6: LED-based photoacoustic imaging
  system for preclinical cancer research`
                      : `Acoustic X Webinar #6: LED-basierte photoakustische Bildgebungssystem in der vorklinischen Krebsforschung`}
                  </p>
                </div>
              </div>
              <div className="slide__link">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/5yjFyEBrX1U"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <div className="w560">
                  <p className="message-label-bold">
                    {intl.locale === "en"
                      ? `Acoustic X Webinar #7: Photoacoustic Imaging Capabilities of
  Light-Emitting-Diode (LED) Arrays`
                      : `Acoustic X Webinar #7: Möglichkeiten der photoakustischen Bildgebung mit LED-Reihen`}
                  </p>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section>

      <section className="sect sect-faq bg-white sect-imaging">
        <div className="container">
          <h2 className="h3 mb-6">
            {intl.locale === "en"
              ? `Featured Publications`
              : `Ausgezeichnete Publikationen`}
          </h2>
          <Accordion>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `LED-based photoacoustic imaging: From bench to bedside`
                    : `LED-based photoacoustic imaging: From bench to bedside`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className="flex-row">
                  <div className="flex-column-4">
                    <h3>
                      {intl.locale === "en" ? `Editor:` : `Herausgeber: `}
                    </h3>
                    <p className="mb-8">
                      {intl.locale === "en"
                        ? `Mithun Kuniyil Ajith Singh`
                        : `Mithun Kuniyil Ajith Singh `}
                    </p>
                    <a
                      href="https://link.springer.com/book/10.1007/978-981-15-3984-8"
                      className="link-simple-black"
                    >
                      {intl.locale === "en" ? `Read more` : `Mehr erfahren`}
                    </a>
                  </div>
                  <div className="flex-column-8">
                    <h3>
                      {intl.locale === "en" ? `Summary:` : `Zusammenfassung:`}
                    </h3>
                    {intl.locale === "en" ? (
                      <ul>
                        <li>First book on LED-based photoacoustic imaging</li>
                        <li>
                          Covers fundamentals, principles, instrumentation,
                          reconstruction, and data/image processing methods
                        </li>
                        <li>
                          Discusses the preclinical and clinical applications of
                          LED-based photoacoustic imaging
                        </li>
                        <li>
                          Includes contributions from industry and academia
                          alike
                        </li>
                        <li>
                          Highlights the opportunities and challenges of
                          clinical translation from an industry perspective
                        </li>
                      </ul>
                    ) : (
                      <ul>
                        <li>
                          Erstes Buch über LED-basierte photoakustische
                          Bildgebung
                        </li>
                        <li>
                          Behandelt Grundlagen, Prinzipien, Instrumentierung,
                          Rekonstruktion und Daten-/Bildverarbeitungsmethoden
                        </li>
                        <li>
                          Erörtert die präklinischen und klinischen Anwendungen
                          der LED-basierten photoakustischen Bildgebung
                        </li>
                        <li>
                          Enthält Beiträge aus Industrie und Wissenschaft
                          gleichermaßen
                        </li>
                        <li>
                          Beleuchtet die Chancen und Herausforderungen der
                          klinischen Umsetzung aus Sicht der Industrie
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `Towards Clinical Translation of LED-Based Photoacoustic
  Imaging: A Review`
                    : `Towards Clinical Translation of LED-Based Photoacoustic Imaging: A Review`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className="flex-row">
                  <div className="flex-column-4">
                    <h3>{intl.locale === "en" ? `Authors:` : `Autoren:`}</h3>
                    <p className="mb-8">
                      {intl.locale === "en"
                        ? `Yunhao Zhu, Ting Feng, Qian Cheng, Xueding Wang, Sidan Du,
  Naoto Sato, Jie Yuan, and Mithun Kuniyil Ajith Singh`
                        : `Yunhao Zhu, Ting Feng, Qian Cheng, Xueding Wang, Sidan Du, Naoto Sato, Jie Yuan, und Mithun Kuniyil Ajith Singh`}
                    </p>
                    <a
                      href="https://www.mdpi.com/1424-8220/20/9/2484"
                      className="link-simple-black"
                    >
                      {intl.locale === "en" ? `Read more` : `Mehr erfahren`}
                    </a>
                  </div>
                  <div className="flex-column-8">
                    <h3>
                      {intl.locale === "en" ? `Summary:` : `Zusammenfassung:`}
                    </h3>
                    {intl.locale === "en" ? (
                      <ul>
                        <li>
                          First scientific review on LED-based photoacoustic
                          imaging
                        </li>
                        <li>
                          Thorough discussion about the development of
                          technology, in a historical perspective
                        </li>
                        <li>
                          Extensively reviewed the range of works from the first
                          report on using LEDs for photoacoustic imaging to
                          latest developments in the field
                        </li>
                        <li>
                          Comparison of different illumination sources used in
                          photoacoustic imaging (lasers, laser diodes and LEDs)
                          in terms of cost, portability, and imaging
                          specifications
                        </li>
                        <li>
                          Summarized all the preclinical and clinical
                          applications of LED-based photoacoustic imaging
                          reported until early 2020
                        </li>
                      </ul>
                    ) : (
                      <ul>
                        <li>
                          Erster wissenschaftlicher Überblick über die
                          LED-basierte photoakustische Bildgebung
                        </li>
                        <li>
                          Gründliche Diskussion über die Entwicklung der
                          Technologie in historischer Perspektive
                        </li>
                        <li>
                          Umfassende Überprüfung der Arbeiten vom ersten Bericht
                          über die Verwendung von LEDs für die photoakustische
                          Bildgebung bis zu den neuesten Entwicklungen auf
                          diesem Gebiet
                        </li>
                        <li>
                          Vergleich verschiedener Beleuchtungsquellen, die in
                          der photoakustischen Bildgebung eingesetzt werden
                          (Laser, Laserdioden und LEDs) in Bezug auf Kosten,
                          Tragbarkeit und Bildgebungsspezifikationen
                        </li>
                        <li>
                          Zusammenfassung aller präklinischen und klinischen
                          Anwendungen der LED-basierten photoakustischen
                          Bildgebung, über die bis Anfang 2020 berichtet wurde
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `Detecting joint inflammation by an LED-based photoacoustic
  imaging system: a feasibility study`
                    : `Detecting joint inflammation by an LED-based photoacoustic imaging system: a feasibility study`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className="flex-row">
                  <div className="flex-column-4">
                    <h3>{intl.locale === "en" ? `Authors:` : `Autoren:`}</h3>
                    <p className="mb-8">
                      {intl.locale === "en"
                        ? `Junggun Jo, Guan Xu, Yunhao Zhu, Mary Burton, Jeffrey
  Sarazin, Elena Schiopu, Girish Gandikota, and Xueding Wang`
                        : `Junggun Jo, Guan Xu, Yunhao Zhu, Mary Burton, Jeffrey Sarazin, Elena Schiopu Schiopu, Girish Gandikota und Xueding Wang`}
                    </p>
                    <a
                      href="https://www.spiedigitallibrary.org/journals/journal-of-biomedical-optics/volume-23/issue-11/110501/Detecting-joint-inflammation-by-an-LED-based-photoacoustic-imaging-system/10.1117/1.JBO.23.11.110501.full?SSO=1"
                      className="link-simple-black"
                    >
                      {intl.locale === "en" ? `Read more` : `Mehr erfahren`}
                    </a>
                  </div>
                  <div className="flex-column-8">
                    <h3>
                      {intl.locale === "en" ? `Summary:` : `Zusammenfassung:`}
                    </h3>
                    {intl.locale === "en" ? (
                      <ul>
                        <li>
                          First clinical pilot study demonstrating the potential
                          of LED-based photoacoustic imaging in inflammatory
                          arthritis
                        </li>
                        <li>
                          LED-photoacoustic images from 12 joints with
                          clinically active arthritis, five joints with
                          subclinically active arthritis, and 12 normal joints
                          were compared with US doppler
                        </li>
                        <li>
                          LED-photoacoustic imaging offered higher sensitivity
                          than US doppler (gold standard) in detecting
                          angiogenic microvasculature
                        </li>
                        <li>
                          Clear demonstration that LED-based photoacoustic
                          imaging is potentially an excellent portable imaging
                          modality for inflammatory arthritis detection and
                          treatment monitoring
                        </li>
                      </ul>
                    ) : (
                      <ul>
                        <li>
                          Erste klinische Pilotstudie, die das Potenzial der
                          LED-basierten photoakustischen Bildgebung bei
                          entzündlicher Arthritis belegt
                        </li>
                        <li>
                          LED-photoakustische Bilder von 12 Gelenken mit
                          klinisch aktiver Arthritis, fünf Gelenken mit
                          subklinisch aktiver Arthritis und 12 normalen Gelenken
                          wurden mit US-Doppler verglichen
                        </li>
                        <li>
                          Die LED-photoakustische Bildgebung bot eine höhere
                          Empfindlichkeit als der US-Doppler (Goldstandard) bei
                          der Erkennung der angiogenen Mikrogefäße
                        </li>
                        <li>
                          Eindeutiger Nachweis, dass die LED-basierte
                          photoakustische Bildgebung eine hervorragende tragbare
                          Bildgebungsmethode für die Erkennung von entzündlicher
                          Arthritis und die Überwachung der Behandlung darstellt
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `Molecular imaging of oxidative stress using an LED-based
  photoacoustic imaging system`
                    : `Molecular imaging of oxidative stress using an LED-based photoacoustic imaging system`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className="flex-row">
                  <div className="flex-column-4">
                    <h3>{intl.locale === "en" ? `Authors:` : `Autoren:`}</h3>
                    <p className="mb-8">
                      {intl.locale === "en"
                        ? `Ali Hariri, Eric Zhao, Ananthakrishna Soundaram
  Jeevarathinam, Jeanne Lemaster, Jianjian Zhang, and Jesse
  V. Jokerst`
                        : `Ali Hariri, Eric Zhao, Ananthakrishna Soundaram Jeevarathinam, Jeanne Lemaster, Jianjian Zhang, and Jesse V. Jokerst`}
                    </p>
                    <a
                      href="https://www.nature.com/articles/s41598-019-47599-2"
                      className="link-simple-black"
                    >
                      {intl.locale === "en" ? `Read more` : `Mehr erfahren`}
                    </a>
                  </div>
                  <div className="flex-column-8">
                    <h3>
                      {intl.locale === "en" ? `Summary:` : `Zusammenfassung:`}
                    </h3>
                    {intl.locale === "en" ? (
                      <ul>
                        <li>
                          First preclinical study demonstrating the molecular
                          imaging capabilities of LED-based photoacoustics
                        </li>
                        <li>
                          In this work, authors measured RONS (reactive oxygen
                          and nitrogen species) in tissue samples using a near
                          infrared absorbing molecule (CyBA) and LED-based
                          photoacoustic imaging
                        </li>
                        <li>
                          When compared to laser-based photoacoustic imaging
                          systems, no photobleaching was observed while using
                          LED-based system for this highly sensitive molecular
                          imaging application
                        </li>
                        <li>
                          Besides the advantage of significant reduction in the
                          size and cost, authors demonstrate the unprecedented
                          potential of LED-based photoacoustics in molecular
                          imaging
                        </li>
                      </ul>
                    ) : (
                      <ul>
                        <li>
                          Erste präklinische Studie, die die molekularen
                          Bildgebungsmöglichkeiten der LED-basierten
                          Photoakustik demonstriert
                        </li>
                        <li>
                          In dieser Arbeit haben die Autoren RONS (reaktive
                          Sauerstoff- und Stickstoffspezies) in Gewebeproben
                          unter Verwendung eines im nahen Infrarot
                          absorbierenden Moleküls (CyBA) und LED-basierter
                          photoakustischer Bildgebung gemessen
                        </li>
                        <li>
                          Im Vergleich zu laserbasierten photoakustischen
                          Bildgebungssystemen wurde bei der Verwendung eines
                          LED-basierten Systems für diese hochempfindliche
                          molekulare Bildgebungsanwendung kein Photobleaching
                          beobachtet
                        </li>
                        <li>
                          Neben dem Vorteil einer erheblichen Verringerung der
                          Größe und der Kosten zeigen die Autoren das
                          beispiellose Potenzial der LED-basierten Photoakustik
                          für die molekulare Bildgebung
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `Tomographic imaging with an ultrasound and LED-based
  photoacoustic system`
                    : `Tomographic imaging with an ultrasound and LED-based photoacoustic system`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className="flex-row">
                  <div className="flex-column-4">
                    <h3>{intl.locale === "en" ? `Authors:` : `Autoren:`}</h3>
                    <p className="mb-8">
                      {intl.locale === "en"
                        ? `Kalloor Joseph Francis, Yoeri E. Boink, Maura Dantuma,
  Mithun Kuniyil Ajith Singh, Srirang Manohar, and Wiendelt
  Steenbergen`
                        : `Kalloor Joseph Francis, Yoeri E. Boink, Maura Dantuma, Mithun Kuniyil Ajith Singh, Srirang Manohar, and Wiendelt Steenbergen`}
                    </p>
                    <a
                      href="https://opg.optica.org/boe/fulltext.cfm?uri=boe-11-4-2152&id=429372"
                      className="link-simple-black"
                    >
                      {intl.locale === "en" ? `Read more` : `Mehr erfahren`}
                    </a>
                  </div>
                  <div className="flex-column-8">
                    <h3>
                      {intl.locale === "en" ? `Summary:` : `Zusammenfassung:`}
                    </h3>
                    {intl.locale === "en" ? (
                      <ul>
                        <li>
                          Reported a method to realize tomographic ultrasound
                          and photoacoustic imaging using an LED-based
                          photoacoustic and ultrasound system
                        </li>
                        <li>
                          Multiple illumination configurations suitable for
                          human finger and animal brain imaging are explored
                          thoroughly
                        </li>
                        <li>
                          High resolution LED-based photoacoustic and ultrasound
                          images of human finger and animal joints were
                          obtained, demonstrating potential of the method in
                          inflammatory arthritis diagnosis and treatment
                          monitoring in a resource-limited preclinical and
                          clinical setting
                        </li>
                        <li>
                          Proposed method can find a wide range of clinical
                          applications, especially in a point-of-care setting,
                          where inexpensive and compact equipment is a
                          requirement
                        </li>
                      </ul>
                    ) : (
                      <ul>
                        <li>
                          Bericht über eine Methode zur Realisierung von
                          tomographischem Ultraschall und photoakustischer
                          Bildgebung unter Verwendung eines LED-basierten
                          photoakustischen und Ultraschallsystems
                        </li>
                        <li>
                          Mehrere Beleuchtungskonfigurationen, die sich für die
                          Bildgebung von menschlichen Fingern und Tiergehirnen
                          eignen, wurden eingehend untersucht
                        </li>
                        <li>
                          Es wurden hochauflösende LED-basierte photoakustische
                          und Ultraschallbilder von menschlichen Finger- und
                          Tiergelenken erhalten. Das Potenzial der Methode für
                          die Diagnose von entzündlicher Arthritis und die
                          Überwachung der Behandlung in einem
                          ressourcenbeschränkten präklinischen und klinischen
                          Umfeld konnten aufgezeigt werden
                        </li>
                        <li>
                          Die vorgeschlagene Methode kann eine breite Palette
                          von klinischen Anwendungen finden, insbesondere im
                          Point-of-Care-Bereich, wo eine kostengünstige und
                          kompakte Ausrüstung erforderlich ist.
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `Handheld Real-Time LED-Based Photoacoustic and Ultrasound
  Imaging System for Accurate Visualization of Clinical Metal
  Needles and Superficial Vasculature to Guide Minimally
  Invasive Procedures`
                    : `Handheld Real-Time LED-Based Photoacoustic and Ultrasound Imaging System for Accurate Visualization of Clinical Metal Needles and Superficial Vasculature to Guide Minimally Invasive Procedures`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className="flex-row">
                  <div className="flex-column-4">
                    <h3>{intl.locale === "en" ? `Authors:` : `Autoren:`}</h3>
                    <p className="mb-8">
                      {intl.locale === "en"
                        ? `Wenfeng Xia, Mithun Kuniyil Ajith Singh, Efthymios Maneas,
  Naoto Sato, Yusuke Shigeta, Toshitaka Agano, Sebastian
  Ourselin, Simeon J. West, and Adrien E. Desjardins`
                        : `Wenfeng Xia, Mithun Kuniyil Ajith Singh, Efthymios Maneas, Naoto Sato, Yusuke Shigeta, Toshitaka Agano, Sebastian Ourselin, Simeon J. West, and Adrien E. Desjardins`}
                    </p>
                    <a
                      href="https://www.mdpi.com/1424-8220/18/5/1394"
                      className="link-simple-black"
                    >
                      {intl.locale === "en" ? `Read more` : `Mehr erfahren`}
                    </a>
                  </div>
                  <div className="flex-column-8">
                    <h3>
                      {intl.locale === "en" ? `Summary:` : `Zusammenfassung:`}
                    </h3>
                    {intl.locale === "en" ? (
                      <ul>
                        <li>
                          First study demonstrating the potential of LED-based
                          photoacoustic imaging in guiding minimally invasive
                          procedures with peripheral vascular targets
                        </li>
                        <li>
                          LED-based PA imaging offered higher contrast in
                          visualizing clinical needle shaft and tip, when
                          compared to ultrasound imaging, the gold standard
                        </li>
                        <li>
                          Using phantom and in vivo human volunteer experiments,
                          authors showed that combined LED-based PA and US
                          imaging holds potential in minimally invasive surgical
                          guidance
                        </li>
                        <li>
                          Demonstrated the potential of portable LED-based
                          system in a resource limited clinical setting for
                          guiding procedures involving metallic clinical needles
                        </li>
                      </ul>
                    ) : (
                      <ul>
                        <li>
                          Erste Studie, die das Potenzial der LED-basierten
                          photoakustischen Bildgebung bei der Steuerung
                          minimalinvasiver Verfahren mit peripheren Gefäßzielen
                          belegt
                        </li>
                        <li>
                          Die LED-basierte PA-Bildgebung bot einen höheren
                          Kontrast bei der Visualisierung des klinischen
                          Nadelschafts und der Nadelspitze im Vergleich zur
                          Ultraschallbildgebung, dem Goldstandard
                        </li>
                        <li>
                          Anhand von Phantom- und In-vivo-Experimenten mit
                          menschlichen Probanden zeigten die Autoren, dass die
                          kombinierte LED-basierte PA- und US-Bildgebung
                          Potenzial für die minimalinvasive chirurgische Führung
                          bietet
                        </li>
                        <li>
                          Demonstration des Potenzials eines tragbaren
                          LED-basierten Systems in einem ressourcenbeschränkten
                          klinischen Umfeld zur Führung von Eingriffen mit
                          klinischen Metallnadeln
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `Diagnosis and Treatment Monitoring of Port-Wine Stain Using
  LED-Based Photoacoustics: Theoretical Aspects and First
  In-Human Clinical Pilot Study`
                    : `Diagnosis and Treatment Monitoring of Port-Wine Stain Using LED-Based Photoacoustics: Theoretical Aspects and First In-Human Clinical Pilot Study`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className="flex-row">
                  <div className="flex-column-4">
                    <h3>{intl.locale === "en" ? `Authors:` : `Autoren:`}</h3>
                    <p className="mb-8">
                      {intl.locale === "en"
                        ? `Qian Cheng, Menglu Qian, Xiuli Wang, Haonan Zhang, Peiru
  Wang, Long Wen, Jing Pan, Ya Gao, Shiying Wu, Mengjiao
  Zhang, Yingna Chen, Naoto Sato, and Xueding Wang`
                        : `Qian Cheng, Menglu Qian, Xiuli Wang, Haonan Zhang, Peiru Wang, Long Wen, Jing Pan, Ya Gao, Shiying Wu, Mengjiao Zhang, Yingna Chen, Naoto Sato, and Xueding Wang`}
                    </p>
                    <a
                      href="https://link.springer.com/chapter/10.1007/978-981-15-3984-8_15"
                      className="link-simple-black"
                    >
                      {intl.locale === "en" ? `Read more` : `Mehr erfahren`}
                    </a>
                  </div>
                  <div className="flex-column-8">
                    <h3>
                      {intl.locale === "en" ? `Summary:` : `Zusammenfassung:`}
                    </h3>
                    {intl.locale === "en" ? (
                      <ul>
                        <li>
                          First clinical pilot study demonstrating the potential
                          of LED-based photoacoustic imaging in diagnosis and
                          treatment monitoring of port wine stain
                        </li>
                        <li>
                          Thorough coverage of both the theoretical and clinical
                          experimental aspects
                        </li>
                        <li>
                          22 patients were included in the study and the
                          capability of LED-based PA imaging was compared with
                          gold standard techniques like dermoscopy
                        </li>
                        <li>
                          New LED-photoacoustic based index was developed which
                          can be used as a guiding tool for port wine stain
                          detection and PDT-based treatment monitoring
                        </li>
                        <li>
                          Clearly demonstrated that LED-based photoacoustics can
                          be a valuable tool for early diagnosis and
                          photodynamic therapy monitoring in port wine stain
                          disease
                        </li>
                      </ul>
                    ) : (
                      <ul>
                        <li>
                          Erste klinische Pilotstudie, die das Potenzial der
                          LED-basierten photoakustischen Bildgebung bei der
                          Diagnose und Behandlungsüberwachung von
                          Portweinflecken aufzeigt
                        </li>
                        <li>
                          Gründliche Behandlung sowohl der theoretischen als
                          auch der klinischen experimentellen Aspekte
                        </li>
                        <li>
                          22 Patienten wurden in die Studie einbezogen und die
                          Leistungsfähigkeit der LED-basierten PA-Bildgebung
                          wurde mit Goldstandardtechniken wie der Dermatoskopie
                          verglichen
                        </li>
                        <li>
                          Es wurde ein neuer LED-photoakustischer Index
                          entwickelt, der als Hilfsmittel zur Erkennung von
                          Portweinflecken und zur Überwachung der PDT-Behandlung
                          verwendet werden kann
                        </li>
                        <li>
                          Es wurde eindeutig nachgewiesen, dass die LED-basierte
                          Photoakustik ein wertvolles Instrument für die
                          Frühdiagnose und die Überwachung der photodynamischen
                          Therapie bei der Portweinfleckenkrankheit sein kann
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
      </section>

      <section className="sect sect_TreatmentCvd_2f8as2 bg-bls">
        <div className="container">
          <p className="message-label-bold txt txt-rot">
            {intl.locale === "en"
              ? `Peer-reviewed journal articles using AcousticX`
              : `Von Fachleuten begutachtete Zeitschriftenartikel mit AcousticX`}
          </p>
          <div className="√">
            {/* ここは動的に */}
            <Slider {...settings}>
              <div className="slide__link w560 d-flex news-card-wrap p-2">
                <div class="news-card">
                  <p class="slide-title__data font_inter">
                    Sensors 20 (13), 3789 (2020)
                  </p>
                  <h2 class="title">
                    M. Kuriakose, C.D. Nguyen, M. Kuniyil Ajith Singh et al.
                  </h2>
                  <p class="excerpt">
                    Optimizing Irradiation Geometry in LED-Based Photoacoustic
                    Imaging with 3D Printed Flexible and Modular Light Delivery
                    System
                  </p>
                  <div class="news-link">
                    <a class="" href="/">
                      {intl.locale === "en" ? `Read more` : `Mehr erfahren`}
                    </a>
                  </div>
                </div>
              </div>
              <div className="slide__link w560 d-flex news-card-wrap p-2">
                <div class="news-card">
                  <p class="slide-title__data font_inter">
                    Sensors 20 (9), 2484 (2020).
                  </p>
                  <h2 class="title">Y. Zhu, T. Feng, Q. Cheng et al.</h2>
                  <p class="excerpt">
                    Towards Clinical Translation of LED-Based Photoacoustic
                    Imaging: A Review
                  </p>
                  <div class="news-link">
                    <a class="" href="/">
                      {intl.locale === "en" ? `Read more` : `Mehr erfahren`}
                    </a>
                  </div>
                </div>
              </div>
              <div className="slide__link w560 d-flex news-card-wrap p-2">
                <div class="news-card">
                  <p class="slide-title__data font_inter">
                    Biomedical Optics Express 11 (6), 3360-3373 (2020).
                  </p>
                  <h2 class="title">A. Hariri, K. Alipour, Y. Mantri et al.</h2>
                  <p class="excerpt">
                    Deep learning improves contrast in low-fluence photoacoustic
                    imaging
                  </p>
                  <div class="news-link">
                    <a class="" href="/">
                      {intl.locale === "en" ? `Read more` : `Mehr erfahren`}
                    </a>
                  </div>
                </div>
              </div>
              <div className="slide__link w560 d-flex news-card-wrap p-2">
                <div class="news-card">
                  <p class="slide-title__data font_inter">
                    Sensors 20 (10), 2793 (2020)
                  </p>
                  <h2 class="title">
                    F. Kalloor Joseph, R. Booijink, R. Bansal et al.
                  </h2>
                  <p class="excerpt">
                    Tomographic Ultrasound and LED-Based Photoacoustic System
                    for Preclinical Imaging,
                  </p>
                  <div class="news-link">
                    <a class="" href="/">
                      {intl.locale === "en" ? `Read more` : `Mehr erfahren`}
                    </a>
                  </div>
                </div>
              </div>
              <div className="slide__link w560 p-2">
                <div class="news-card">
                  <p class="slide-title__data font_inter">
                    Biomedical Optics Express 11 (5), 2533-2547 (2020)
                  </p>
                  <h2 class="title">
                    P. Farnia, E. Najafzadeh, A. Hariri et al.
                  </h2>
                  <p class="excerpt">
                    Dictionary learning technique enhances signal in LED-based
                    photoacoustic imaging
                  </p>
                  <div class="news-link">
                    <a class="" href="/">
                      {intl.locale === "en" ? `Read more` : `Mehr erfahren`}
                    </a>
                  </div>
                </div>
              </div>
              <div className="slide__link w560 p-2">
                <div class="news-card">
                  <p class="slide-title__data font_inter">
                    Biomedical Optics Express 11 (4), 2152-2165 (2020)
                  </p>
                  <h2 class="title">
                    F. Kalloor Joseph, Y.E. Boink, M. Dantuma et al.
                  </h2>
                  <p class="excerpt">
                    Tomographic imaging with an ultrasound and LED-based
                    photoacoustic system
                  </p>
                  <div class="news-link">
                    <a class="" href="/">
                      {intl.locale === "en" ? `Read more` : `Mehr erfahren`}
                    </a>
                  </div>
                </div>
              </div>
              <div className="slide__link w560 p-2">
                <div class="news-card">
                  <p class="slide-title__data font_inter">
                    Sensors, 19(22), p.4861 (2019)
                  </p>
                  <h2 class="title">S. Agrawal, C. FaddeN, A. Dang et al.</h2>
                  <p class="excerpt">
                    Light-Emitting-Diode-Based Multispectral Photoacoustic
                    Computed Tomography System
                  </p>
                  <div class="news-link">
                    <a class="" href="/">
                      {intl.locale === "en" ? `Read more` : `Mehr erfahren`}
                    </a>
                  </div>
                </div>
              </div>
              <div className="slide__link w560 p-2">
                <div class="news-card">
                  <p class="slide-title__data font_inter">
                    Journal of Biophotonics, e201900167 (2019)
                  </p>
                  <h2 class="title">E. Maneas, R. Aughwane, N. Huynh et al.</h2>
                  <p class="excerpt">
                    Photoacoustic imaging of the human placental vasculature
                  </p>
                  <div class="news-link">
                    <a class="" href="/">
                      {intl.locale === "en" ? `Read more` : `Mehr erfahren`}
                    </a>
                  </div>
                </div>
              </div>
              <div className="slide__link w560 p-2">
                <div class="news-card">
                  <p class="slide-title__data font_inter">
                    Scientific Reports,9, 11378 (2019)
                  </p>
                  <h2 class="title">
                    A. Hariri, E. Zhao, A.S. Jeevarathinam et al.
                  </h2>
                  <p class="excerpt">
                    Molecular imaging of oxidative stress using an LED- based
                    photoacoustic imaging system
                  </p>
                  <div class="news-link">
                    <a class="" href="/">
                      {intl.locale === "en" ? `Read more` : `Mehr erfahren`}
                    </a>
                  </div>
                </div>
              </div>
              <div className="slide__link w560 p-2">
                <div class="news-card">
                  <p class="slide-title__data font_inter">
                    Wound Rep Reg, 27: 488-496 (2019)
                  </p>
                  <h2 class="title">A. Hariri, F. Chen, C. Moore, C et al.</h2>
                  <p class="excerpt">
                    Noninvasive staging of pressure ulcers using photoacoustic
                    imaging
                  </p>
                  <div class="news-link">
                    <a class="" href="/">
                      {intl.locale === "en" ? `Read more` : `Mehr erfahren`}
                    </a>
                  </div>
                </div>
              </div>
              <div className="slide__link w560 p-2">
                <div class="news-card">
                  <p class="slide-title__data font_inter">
                    Tissue Engineering Part C: Methods, 25:9, 523-531 (2019)
                  </p>
                  <h2 class="title">Y. Zhu, X. Lu, X. Dong et al.</h2>
                  <p class="excerpt">
                    LED-Based Photoacoustic Imaging for Monitoring Angiogenesis
                    in Fibrin Scaffolds
                  </p>
                  <div class="news-link">
                    <a class="" href="/">
                      {intl.locale === "en" ? `Read more` : `Mehr erfahren`}
                    </a>
                  </div>
                </div>
              </div>
              <div className="slide__link w560 p-2">
                <div class="news-card">
                  <p class="slide-title__data font_inter">
                    Ultrasonic Imaging, 41(5), 301–316 (2019).
                  </p>
                  <h2 class="title">
                    S.R. Miri Rostami, M. Mozaffarzadeh, M. Ghaffari-Miab et al.
                  </h2>
                  <p class="excerpt">
                    GPU-accelerated Double-stage Delay-multiply-and-sum
                    Algorithm for Fast Photoacoustic Tomography Using LED
                    Excitation and Linear Arrays
                  </p>
                  <div class="news-link">
                    <a class="" href="/">
                      {intl.locale === "en" ? `Read more` : `Mehr erfahren`}
                    </a>
                  </div>
                </div>
              </div>
              <div className="slide__link w560 p-2">
                <div class="news-card">
                  <p class="slide-title__data font_inter">
                    Biomedical Optics Express, 9, 3852-3866 (2018)
                  </p>
                  <h2 class="title">E.M.A. Anas, H.K. Zhang, J. Kang et al</h2>
                  <p class="excerpt">
                    Enabling fast and high quality LED photoacoustic imaging: a
                    recurrent neural networks based approach
                  </p>
                  <div class="news-link">
                    <a class="" href="/">
                      {intl.locale === "en" ? `Read more` : `Mehr erfahren`}
                    </a>
                  </div>
                </div>
              </div>
              <div className="slide__link w560 p-2">
                <div class="news-card">
                  <p class="slide-title__data font_inter">
                    Photoacoustics, 12, 22-29 (2018)
                  </p>
                  <h2 class="title">
                    M. Mozaffarzadeh, A. Hariri, C. Moore et al.
                  </h2>
                  <p class="excerpt">
                    The double-stage delay-multiply-and-sum image reconstruction
                    method improves imaging quality in a LED-based photoacoustic
                    array scanner
                  </p>
                  <div class="news-link">
                    <a class="" href="/">
                      {intl.locale === "en" ? `Read more` : `Mehr erfahren`}
                    </a>
                  </div>
                </div>
              </div>
              <div className="slide__link w560 p-2">
                <div class="news-card">
                  <p class="slide-title__data font_inter">
                    International Journal of Engineering and Technology, 7(4),
                    4300-4303 (2018)
                  </p>
                  <h2 class="title">
                    T. Agano, M. Kuniyil Ajith Singh, R. Nagaoka et al.
                  </h2>
                  <p class="excerpt">
                    Effect of light pulse width on frequency characteristics of
                    photoacoustic signal –an experimental study using a
                    pulse-width tunable LED- based photoacoustic imaging system
                  </p>
                  <div class="news-link">
                    <a class="" href="/">
                      {intl.locale === "en" ? `Read more` : `Mehr erfahren`}
                    </a>
                  </div>
                </div>
              </div>
              <div className="slide__link w560 p-2">
                <div class="news-card">
                  <p class="slide-title__data font_inter">
                    Journal of Biomedical Optics, 23(11), 110501 (2018).
                  </p>
                  <h2 class="title">J. Jo, G. Xu, Y. Zhu et al.</h2>
                  <p class="excerpt">
                    Detecting joint inflammation by an LED-based photoacoustic
                    imaging system: a feasibility study
                  </p>
                  <div class="news-link">
                    <a class="" href="/">
                      {intl.locale === "en" ? `Read more` : `Mehr erfahren`}
                    </a>
                  </div>
                </div>
              </div>
              <div className="slide__link w560 p-2">
                <div class="news-card">
                  <p class="slide-title__data font_inter">
                    Scientific Reports, 8, 9885 (2018)
                  </p>
                  <h2 class="title">Y. Zhu, G. Xu, J. Yuan et al.</h2>
                  <p class="excerpt">
                    Light Emitting Diodes based Photoacoustic Imaging and
                    Potential Clinical Applications
                  </p>
                  <div class="news-link">
                    <a class="" href="/">
                      {intl.locale === "en" ? `Read more` : `Mehr erfahren`}
                    </a>
                  </div>
                </div>
              </div>
              <div className="slide__link w560 p-2">
                <div class="news-card">
                  <p class="slide-title__data font_inter">
                    Sensors, 18(5), 1394 (2018)
                  </p>
                  <h2 class="title">
                    W. Xia, M. Kuniyil Ajith Singh, E. Maneas et al.
                  </h2>
                  <p class="excerpt">
                    Handheld Real-Time LED-Based Photoacoustic and Ultrasound
                    Imaging System for Accurate Visualization of Clinical Metal
                    Needles and Superficial Vasculature to Guide Minimally
                    Invasive Procedures
                  </p>
                  <div class="news-link">
                    <a class="" href="/">
                      {intl.locale === "en" ? `Read more` : `Mehr erfahren`}
                    </a>
                  </div>
                </div>
              </div>
              <div className="slide__link w560 p-2">
                <div class="news-card">
                  <p class="slide-title__data font_inter">
                    Photoacoustics, 9, 10-20 (2018)
                  </p>
                  <h2 class="title">A. Hariri, J. Lemaster, J. Wang et al.</h2>
                  <p class="excerpt">
                    The characterization of an economic and portable LED-based
                    photoacoustic imaging system to facilitate molecular imaging
                  </p>
                  <div class="news-link">
                    <a class="" href="/">
                      {intl.locale === "en" ? `Read more` : `Mehr erfahren`}
                    </a>
                  </div>
                </div>
              </div>
            </Slider>
            {/* ここまで */}
          </div>
          <a href="/news/" className="link-simple d-inline-flex mt-6">
            {intl.locale === "en" ? `All articles` : `Alle Artikel`}
          </a>
        </div>
      </section>

      <ImagingTable />

      <section className="p-0 py-120px position-relative">
        <StaticImage
          src="../../images/products/imaging/lumbar-type-spec-bg@2x.png"
          className="bg-absolute"
        />

        <div className="container">
          <div className="container__inner flex-row">
            <div className="flex-column-6 txt txt-white">
              <h2>
                {intl.locale === "en" ? `Specifications` : `Spezifikationen`}
              </h2>
              <div className="flex-row mb-4">
                <div className="flex-column-6">
                  <div className="mb-2">
                    <p className="fwb mb-1">
                      {intl.locale === "en"
                        ? `Maximum depth`
                        : `Maximale Tiefe`}
                    </p>
                    <p className="">{intl.locale === "en" ? `40mm` : `40mm`}</p>
                  </div>
                  <div className="mb-2">
                    <p className="fwb mb-1">
                      {intl.locale === "en" ? `Light source` : `Lichtquelle`}
                    </p>
                    {intl.locale === "en" ? (
                      <p className="">
                        High Density High Power LED
                        <br />
                        Wavelength can be selected from below
                        <br />
                        470, 520, 620, 660, 690, <br />
                        750, 820, 850, 940, 980nm, <br />
                        Combination: 690/850, 750/850, 820/940
                        <br />
                        (Can be set at 350nm to 1500nm upon request)
                        <br />
                        Frequency: Selectable from 1, 2, 3 or 4kHz
                        <br />
                        Pulse width: Selectable from 30ns to 150ns
                      </p>
                    ) : (
                      <p className="">
                        High Density High Power LED
                        <br />
                        Wellenlänge kann von folgenden Werten ausgewählt werden:
                        <br />
                        470, 520, 620, 660, 690, <br />
                        750, 820, 850, 940, 980nm, <br />
                        Kombinationen: 690/850, 750/850, 820/940
                        <br />
                        (Kann auf Wunsch auf 350nm bis 1500nm eingestellt
                        werden)
                        <br />
                        Frequenz: auswählbar von 1, 2, 3 oder 4kHz
                        <br />
                        Impulsweite: auswählbar von 30ns bis 150ns
                      </p>
                    )}
                  </div>
                  <div className="mb-2">
                    <p className="fwb mb-1">
                      {intl.locale === "en"
                        ? `Driver ports`
                        : `Treiberschnittstellen`}
                    </p>
                    <p className="">{intl.locale === "en" ? `4` : `4`}</p>
                  </div>
                  <div className="mb-2">
                    <p className="fwb mb-1">
                      {intl.locale === "en" ? `Transducer` : `Sonde`}
                    </p>
                    <p className="">
                      {intl.locale === "en"
                        ? `Selectable from 7MHz or 10MHz linear array transducer`
                        : `Auswahl von 7MHz oder 10MHz linearer Sonde`}
                    </p>
                  </div>
                </div>
                <div className="flex-column-6">
                  <div className="mb-2">
                    <p className="fwb mb-1">
                      {intl.locale === "en"
                        ? `PA processing channels`
                        : `PA Verarbeitungskanäle`}
                    </p>
                    <p className="">
                      {intl.locale === "en"
                        ? `128ch parallel receiving`
                        : `128ch paralleler Empfang`}
                    </p>
                  </div>
                  <div className="mb-2">
                    <p className="fwb mb-1">
                      {intl.locale === "en" ? `Scan mode` : `Scanmodus`}
                    </p>
                    <p className="">
                      {intl.locale === "en"
                        ? `Photoacoustic 2D, Ultrasound 2D`
                        : `Photoacoustic 2D, Ultrasound 2D`}
                    </p>
                  </div>
                  <div className="mb-2">
                    <p className="fwb mb-1">
                      {intl.locale === "en"
                        ? `External trigger output`
                        : `Extener Triggerausgang`}
                    </p>
                    <p className="">
                      {intl.locale === "en"
                        ? `For pulsed laser system`
                        : `Für das pulsierende Lasersystem`}
                    </p>
                  </div>
                  <div className="mb-2">
                    <p className="fwb mb-1">
                      {intl.locale === "en" ? `Power` : `Leistung`}
                    </p>
                    <p className="">
                      {intl.locale === "en"
                        ? `AC 100 V - 240 V`
                        : `AC 100 V - 240 V`}
                    </p>
                  </div>
                  <div className="mb-2">
                    <p className="fwb mb-1">
                      {intl.locale === "en" ? `Monitor` : `Monitor`}
                    </p>
                    <p className="">
                      {intl.locale === "en" ? `Color LCD` : `Farb-LCD`}
                    </p>
                  </div>
                  <div className="mb-2">
                    <p className="fwb mb-1">
                      {intl.locale === "en"
                        ? `Software Option`
                        : `Software Optionen`}
                    </p>
                    <p className="">
                      {intl.locale === "en"
                        ? `3D imaging package (integrated automatic stage),
  oxygeneration package, High Speed Photoacoustic imaging
  package, Raw data accessible`
                        : `3D Bildgebungspaket (integrierte Automatikstufe), Sauerstoffsättigungspaket, Photoakustische Hochgeschwindigkeitsbildgebung, Rohdaten zugänglich`}
                    </p>
                  </div>
                  <div className="mb-2">
                    <p className="fwb mb-1">
                      {intl.locale === "en" ? `Environment` : `Umwelt`}
                    </p>
                    {intl.locale === "en" ? (
                      <p className="">
                        No protective goggles needed
                        <br />
                        No need to define laser class
                      </p>
                    ) : (
                      <p className="">
                        Keine Schutzbrille notwendig
                        <br />
                        Muss keiner Laserklasse zugeordnet werden
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <p>
                {intl.locale === "en"
                  ? `*Above figures may differ subject to options and settings.`
                  : `*Die oben genannten Zahlen können je nach Optionen und Einstellungen abweichen.`}
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="sect sect-qa bg-bge">
        <div className="container">
          <p className="h2 mb-5">
            {intl.locale === "en"
              ? `Learn more about our products`
              : `Erfahren Sie mehr über unsere Produkte`}
          </p>
          <Accordion>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  Motion Principle of HAL
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `HAL can sensor faint BES that trickle onto the skin, process
  it, and simultaneously assist one’s desired movement with
  motors located on each hip and knee joint.`
                    : `HAL kann kleinste bioelektrische Signale auf der Haut erkennen, diese verarbeiten und gleichzeitig die beabsichtige Bewegung  durch Motoren an jedem Hüft- und Kniegelenk unterstützen. `}
                </p>
                <Link
                  to="/products/medical-device/hal-motion-principal/"
                  className="link-button"
                >
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>HAL Lower Limb Type</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Think, Send, Read, Move—HAL is the World first neurologically
  controlled wearable Cyborg which puts your intentions into
  action. HAL improves locomotor activity in stroke, spinal cord
  injury and other neuromuscular disease patients.`
                    : `Denken, Senden, Lesen, Bewegen - HAL ist der weltweit erste neurologisch kontrollierte tragbare Cyborg, der Ihre Absichten in Aktionen umsetzt. HAL verbessert die Bewegungsfähigkeit nach Schlaganfällen, Rückenmarksverletzungen und anderen neuromuskulären Erkrankungen.`}
                </p>
                <Link
                  to="/products/medical-device/hal-limb/"
                  className="link-button"
                >
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>HAL Single Joint</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `HAL Single Joint is a portable and lightweight unit which enables intensive and repetitive training of specific joints such as elbow, knee, and ankle.`
                    : `HAL Single Joint ist ein portables und leichtes Gerät, das ein intensives und wiederholtes Training eines speziellen Gelenkes, wie zum Beispiel Ellbogen, Knie und Sprunggelenk, ermöglicht.`}
                </p>
                <Link
                  to="/products/medical-device/hal-joint/"
                  className="link-button"
                >
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>HAL Lumbar Type</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Improving a quality of life by ensuring your well-being, reducing fatigue in the work environment, regaining your strength and mobility, and expanding your capability.`
                    : `Verbesserung der Lebensqualität durch Sicherstellung Ihres Wohlbefindens, Reduzierung der Ermüdung im Arbeitsumfeld, Wiedererlangen Ihrer Kraft und Mobilität, sowie Ausbau Ihrer Leistungsfähigkeit.`}
                </p>
                <Link
                  to="/products/non-medical-device/"
                  className="link-button"
                >
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `Cleaning Robot`
                    : `Reinigungsroboter`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Keeping large facilities such as airport, shopping malls,
  hotels, and conference centers clean has never been easier.
  Find out how our cleaning and disinfection robot keeps your
  space tidy without the need for human operation.`
                    : `Es war nie einfacher große Gebäude wie Flughäfen, Einkaufszentren, Hotels und Konferenzzentren sauber zu halten. Finden Sie heraus wie unser Reinigungsroboter Ihre Räume ohne menschliche Unterstützung sauber hält.`}
                </p>
                <div>
                  <Link to="/products/cleaning-robot/" className="link-button">
                    {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                  </Link>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>CYIN</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Ingenious interfaces for people including those suffer from
  progressive neurodegenerative disease. CYIN utilizes
  biological information to help communicate with ease, despite
  all their limitations.`
                    : `Geniale Schnittstelle für Menschen, einschließlich derer die an progressiven neurodegenerativen Erkrankungen leiden. CYIN nutzt biologische Informationen um die Kommunikation, unabhängig all Ihrer Einschränkungen, zu vereinfachen.`}
                </p>
                <div>
                  <Link to="/products/communication/" className="link-button">
                    {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                  </Link>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
            {/* <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>Acoustic X</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Acoustic X is photoacoustic imaging technology that helps
  detect melanin, lipid, collages, water, and contrast agents
  specifically designed to locate various biomarkers.`
                    : `Acoustic X ist eine photoakustische Bildgebungstechnologie die Melanin, Fette, Kollagen und Kontrastmittel zur Lokalisation bestimmter Biomarker, erkennt.`}
                </p>
                <div>
                  <Link to="/products/imaging/" className="link-button">
                                      {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}

                  </Link>
                </div>
              </AccordionItemPanel>
            </AccordionItem> */}
          </Accordion>
        </div>
      </section>

      <section className="sect sect_Product__footer p-0">
        <div className="container py-8 my-8 flex-row">
          <div className="flex-column-6">
            <h2 className="h3">
              {intl.locale === "en"
                ? `Want to explore clinical application opportunities?`
                : `Wollen Sie klinische Anwendungsbereiche untersuchen`}
            </h2>
            <p>
              {intl.locale === "en"
                ? `Let's brainstorm with Cyberdyne for the idea`
                : `Denken Sie gemeinsam mit Cyberdyne über die Idee nach`}
            </p>
            <div>
              <Link to="/contact/" className="link_button">
                {intl.locale === "en" ? `Get in Touch` : `Kontaktieren Sie uns`}
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ProductsNonMedicalDevice;
