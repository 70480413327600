import React from "react";
import { useIntl } from "gatsby-plugin-react-intl";

const ImagingTable = ({ image, title, label, content }) => {
  const intl = useIntl();

  return (
    <section className="sect">
      <div className="container">
        <h3 className="h3">
          {intl.locale === "en" ? `AcousticX Users and Collaborators` : ``}
        </h3>

        <div className="acoustic-table-wrap">
          {intl.locale === "en" ? (
            <table className="acoustic-table">
              <tbody>
                <tr>
                  <th>Country</th>
                  <th>Name</th>
                  <th>Position</th>
                  <th>Department/Research Group</th>
                  <th>Institute</th>
                </tr>
                <tr>
                  <td>USA </td>
                  <td>Prof. Xueding Wang</td>
                  <td>Professor, Group Leader</td>
                  <td>Optical Imaging Laboratory</td>
                  <td>Michigan University</td>
                </tr>

                <tr>
                  <td>USA </td>
                  <td>Dr. Jesse Jokerst</td>
                  <td>Assistant Professor, Group Leader</td>
                  <td>Jokerst Bioimaging Lab</td>
                  <td>UC San Diego</td>
                </tr>

                <tr>
                  <td>USA </td>
                  <td>Dr. Srivalleesha Mallidi</td>
                  <td>Assistant Professor, Group Leader</td>
                  <td>
                    Integrated Biofunctional Imaging and Therapeutics
                    Laboratory/ Wellman Center of Photomedicine
                  </td>
                  <td>Tufts University/Harvard Medical School</td>
                </tr>

                <tr>
                  <td>USA </td>
                  <td>Dr. Sri-Rajasekhar Kothapalli</td>
                  <td>Assistant Professor, Group Leader</td>
                  <td>Biophotonics and Ultrasound Imaging Laboratory</td>
                  <td>Pennsylvania State University</td>
                </tr>

                <tr>
                  <td>USA </td>
                  <td>Dr. Kathyayini Sivasubramanian</td>
                  <td>Post-Doctoral Fellow</td>
                  <td>
                    Laboratory of Artificial Intelligence in Medicine and
                    Biomedical Physics
                  </td>
                  <td>Stanford School of Medicine</td>
                </tr>

                <tr>
                  <td>GBR </td>
                  <td>Prof. Adrien Desjardins</td>
                  <td>Professor, Group Leader</td>
                  <td>
                    Interventional Devices Lab/Department of Medical Physics and
                    Biomedical Engineering
                  </td>
                  <td>University College London</td>
                </tr>

                <tr>
                  <td>GBR </td>
                  <td>Dr. Sarah Bohndiek</td>
                  <td>Professor, Junior Group Leader</td>
                  <td>
                    VISION Lab, Department of Physics, Cavendish Laboratory
                  </td>
                  <td>Cancer Research UK Cambridge Institute</td>
                </tr>

                <tr>
                  <td>GBR </td>
                  <td>Dr. Wenfeng Xia</td>
                  <td>Assistant Professor</td>
                  <td>Department of Surgical &amp; Intervention Engineering</td>
                  <td>Kings College London</td>
                </tr>

                <tr>
                  <td>NLD </td>
                  <td>Prof. Wiendelt Steenbergen</td>
                  <td>Professor, Group Leader, Vice Dean - Research (TNW)</td>
                  <td>Biomedical Photonic Imaging Group</td>
                  <td>University of Twente, the Netherlands</td>
                </tr>

                <tr>
                  <td>DEU </td>
                  <td>Prof. Alexander Kuehne</td>
                  <td>Professor, Group Leader</td>
                  <td>Institute of Macromolecular and Organic Chemistry</td>
                  <td>Ulm University</td>
                </tr>

                <tr>
                  <td>ITA </td>
                  <td>Prof. Filippo Molinari</td>
                  <td>Professor, Group Leader</td>
                  <td>
                    Biolab - Department of Electronics and Telecommunications
                  </td>
                  <td>Politecnico di Torino</td>
                </tr>

                <tr>
                  <td>CHN </td>
                  <td>Prof. Qian Cheng</td>
                  <td>Professor, Group Leader</td>
                  <td>
                    Institute of Acoustics, School of Physics Scienece and
                    Engineering
                  </td>
                  <td>Tongji University</td>
                </tr>

                <tr>
                  <td>CHN </td>
                  <td>Prof. Terence T. W. Wong</td>
                  <td>Assistant Professor</td>
                  <td>Department of Chemical and Biological Engineering</td>
                  <td>Hong Kong University of Science and Technology</td>
                </tr>

                <tr>
                  <td>JPN </td>
                  <td>Prof. Yoshifumi Saijo</td>
                  <td>Professor</td>
                  <td>Graduate School of Biomedical Engineering</td>
                  <td>Tohoku University</td>
                </tr>

                <tr>
                  <td>JPN </td>
                  <td>Prof. Ryo Suzuki</td>
                  <td>Professor</td>
                  <td>Laboratory of Drug and Gene Delivery System</td>
                  <td>Teikyo University</td>
                </tr>

                <tr>
                  <td>JPN </td>
                  <td>Prof. Yoichi Negishi</td>
                  <td>Professor</td>
                  <td>
                    Department of Drug Delivery and Molecular Biopharmaceutics
                  </td>
                  <td>Tokyo University of Pharmacy and Life Science</td>
                </tr>

                <tr>
                  <td>JPN </td>
                  <td>Dr. Yusuke Tajima</td>
                  <td>Senior Research Scientist</td>
                  <td>
                    RIKEN Center for Advanced Photonics, Ultrahigh Precision
                    Optics Technology Team
                  </td>
                  <td>
                    RIKEN (The Institute of Physical and Chemical Research)
                  </td>
                </tr>

                <tr>
                  <td>JPN </td>
                  <td>Prof. Tetsuji Uemura</td>
                  <td>Medical Professor</td>
                  <td>
                    University Hospital, Plastic and Reconstructive Surgery
                  </td>
                  <td>
                    Saga Medical School, Faculty of Medicine, Saga University
                  </td>
                </tr>
              </tbody>
            </table>
          ) : (
            <table>
              <tbody>
                <tr>
                  <th>Country</th>
                  <th>Name</th>
                  <th>Position</th>
                  <th>Department/Research Group</th>
                  <th>Institute</th>
                </tr>
                <tr>
                  <td>USA </td>
                  <td>Prof. Xueding Wang</td>
                  <td>Professor, Group Leader</td>
                  <td>Optical Imaging Laboratory</td>
                  <td>Michigan University</td>
                </tr>

                <tr>
                  <td>USA </td>
                  <td>Dr. Jesse Jokerst</td>
                  <td>Assistant Professor, Group Leader</td>
                  <td>Jokerst Bioimaging Lab</td>
                  <td>UC San Diego</td>
                </tr>

                <tr>
                  <td>USA </td>
                  <td>Dr. Srivalleesha Mallidi</td>
                  <td>Assistant Professor, Group Leader</td>
                  <td>
                    Integrated Biofunctional Imaging and Therapeutics
                    Laboratory/ Wellman Center of Photomedicine
                  </td>
                  <td>Tufts University/Harvard Medical School</td>
                </tr>

                <tr>
                  <td>USA </td>
                  <td>Dr. Sri-Rajasekhar Kothapalli</td>
                  <td>Assistant Professor, Group Leader</td>
                  <td>Biophotonics and Ultrasound Imaging Laboratory</td>
                  <td>Pennsylvania State University</td>
                </tr>

                <tr>
                  <td>USA </td>
                  <td>Dr. Kathyayini Sivasubramanian</td>
                  <td>Post-Doctoral Fellow</td>
                  <td>
                    Laboratory of Artificial Intelligence in Medicine and
                    Biomedical Physics
                  </td>
                  <td>Stanford School of Medicine</td>
                </tr>

                <tr>
                  <td>GBR </td>
                  <td>Prof. Adrien Desjardins</td>
                  <td>Professor, Group Leader</td>
                  <td>
                    Interventional Devices Lab/Department of Medical Physics and
                    Biomedical Engineering
                  </td>
                  <td>University College London</td>
                </tr>

                <tr>
                  <td>GBR </td>
                  <td>Dr. Sarah Bohndiek</td>
                  <td>Professor, Junior Group Leader</td>
                  <td>
                    VISION Lab, Department of Physics, Cavendish Laboratory
                  </td>
                  <td>Cancer Research UK Cambridge Institute</td>
                </tr>

                <tr>
                  <td>GBR </td>
                  <td>Dr. Wenfeng Xia</td>
                  <td>Assistant Professor</td>
                  <td>Department of Surgical &amp; Intervention Engineering</td>
                  <td>Kings College London</td>
                </tr>

                <tr>
                  <td>NLD </td>
                  <td>Prof. Wiendelt Steenbergen</td>
                  <td>Professor, Group Leader, Vice Dean - Research (TNW)</td>
                  <td>Biomedical Photonic Imaging Group</td>
                  <td>University of Twente, the Netherlands</td>
                </tr>

                <tr>
                  <td>DEU </td>
                  <td>Prof. Alexander Kuehne</td>
                  <td>Professor, Group Leader</td>
                  <td>Institute of Macromolecular and Organic Chemistry</td>
                  <td>Ulm University</td>
                </tr>

                <tr>
                  <td>ITA </td>
                  <td>Prof. Filippo Molinari</td>
                  <td>Professor, Group Leader</td>
                  <td>
                    Biolab - Department of Electronics and Telecommunications
                  </td>
                  <td>Politecnico di Torino</td>
                </tr>

                <tr>
                  <td>CHN </td>
                  <td>Prof. Qian Cheng</td>
                  <td>Professor, Group Leader</td>
                  <td>
                    Institute of Acoustics, School of Physics Scienece and
                    Engineering
                  </td>
                  <td>Tongji University</td>
                </tr>

                <tr>
                  <td>CHN </td>
                  <td>Prof. Terence T. W. Wong</td>
                  <td>Assistant Professor</td>
                  <td>Department of Chemical and Biological Engineering</td>
                  <td>Hong Kong University of Science and Technology</td>
                </tr>

                <tr>
                  <td>JPN </td>
                  <td>Prof. Yoshifumi Saijo</td>
                  <td>Professor</td>
                  <td>Graduate School of Biomedical Engineering</td>
                  <td>Tohoku University</td>
                </tr>

                <tr>
                  <td>JPN </td>
                  <td>Prof. Ryo Suzuki</td>
                  <td>Professor</td>
                  <td>Laboratory of Drug and Gene Delivery System</td>
                  <td>Teikyo University</td>
                </tr>

                <tr>
                  <td>JPN </td>
                  <td>Prof. Yoichi Negishi</td>
                  <td>Professor</td>
                  <td>
                    Department of Drug Delivery and Molecular Biopharmaceutics
                  </td>
                  <td>Tokyo University of Pharmacy and Life Science</td>
                </tr>

                <tr>
                  <td>JPN </td>
                  <td>Dr. Yusuke Tajima</td>
                  <td>Senior Research Scientist</td>
                  <td>
                    RIKEN Center for Advanced Photonics, Ultrahigh Precision
                    Optics Technology Team
                  </td>
                  <td>
                    RIKEN (The Institute of Physical and Chemical Research)
                  </td>
                </tr>

                <tr>
                  <td>JPN </td>
                  <td>Prof. Tetsuji Uemura</td>
                  <td>Medical Professor</td>
                  <td>
                    University Hospital, Plastic and Reconstructive Surgery
                  </td>
                  <td>
                    Saga Medical School, Faculty of Medicine, Saga University
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      </div>
    </section>
  );
};

export default ImagingTable;
